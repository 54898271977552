import { Areas } from "src/app/models/users/Areas";
import { StoreTypes } from "src/app/models/users/StoreTypes";
import { StoreUsers } from "./StoreUsers";
import { UserTypes } from "./UserTypes";

export class Users {
  constructor(
    public id : string = "",
    public password : string = "",
    public passwordConfirm : string = "",
    public nowPassword : string = "",
    public userTypes : UserTypes = {
      "seq" : 0,
      "name" : null
    },
    public shopName : string = "",
    public ownerName : string = "",
    public phone : string = "",
    public areas : Areas = {
      "seq" : 0,
      "name" : null
    },
    public address : string = "",
    public zipCode : string = "", 
    public regDate : string = "",
    public updateDate : string = "",
    public parent : Users = null,
    public xgaLogoImage : string = null,
    public xgaLogoSize : number = 0,
    public hdLogoImage : string = null,
    public hdLogoSize : number = 0,
    public defaultMenuImage : string = null,
    public defaultSize : number = 0,
    public useNoline : boolean = false,
    public useKiosk : boolean = false,
    public joinType : string = "noline",
    public storeUsers : StoreUsers = new StoreUsers(),
    public open : boolean = false
  ) {}
}
