import { Observable, Subject } from "rxjs";
import "rxjs/add/operator/toPromise";
import { Injectable } from "@angular/core";
import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { Router } from "@angular/router";
import { tokenNotExpired } from "angular2-jwt";

import { CommonResponse } from "src/app/models/CommonResponse";

@Injectable()
export class AJAXService {
  constructor(
      private http : Http,
      private router : Router
    ) {}

    private checkToken() : void {
      if (!tokenNotExpired()) {
        this.router.navigateByUrl("/login");
      }
    }

  public getData(url: string): Promise<Object> {
    return this.http
        .get(url, this.getGETHeader())
        .toPromise()
        .then(this.parseResponse);
  }

  public getDataWithCondition(url: string, condition: {}): Promise<Object> {
    return this.http
        .post(url, JSON.stringify(condition), this.getPOSTHeader())
        .toPromise()
        .catch(res => {
          if (!res.ok) {
            // server error
            let response : CommonResponse = new CommonResponse();
            return response;
          }
        })
        .then(this.parseResponse);
  }

  public getList(url: string): Promise<Object[]> {
    return this.http
        .get(url, this.getGETHeader())
        .toPromise()
        .then(this.parseResponse);
  }

  public getListWithCondition(url: string, condition: {}): Promise<Object[]> {
    return this.http
        .post(url, JSON.stringify(condition), this.getPOSTHeader())
        .toPromise()
        .then(this.parseResponse);
  }

  private getPOSTHeader() : RequestOptions {
    let headers = new Headers({
      "Content-Type" : "application/json",
      "jwt-auth-token" : localStorage.getItem("token"),
      "user" : localStorage.getItem("user"),
      "type" : localStorage.getItem("type")
    });
    return new RequestOptions({headers : headers});
  }

  private getGETHeader() : RequestOptions {
    let headers = new Headers({
      "jwt-auth-token" : localStorage.getItem("token"),
      "user" : localStorage.getItem("user"),
      "type" : localStorage.getItem("type")
    });
    return new RequestOptions({headers : headers});
  }

  private parseResponse(response: Response): any {
    let body : any;

    if (response instanceof CommonResponse) {
      body = response;
    } else {
      body = response.json();
    }

    return body;
  }
}
