// Libraries
import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector : "[OnlyNumber]"
})
export class OnlyNumber {
  constructor(private element : ElementRef) {}

  @Input() OnlyNumber : boolean;

  @HostListener("keydown", ["$event"]) onKeyDown(event) {
    let e = <KeyboardEvent> event;
    if (this.OnlyNumber) {
      if (e.keyCode === 8
          || e.keyCode === 46
          || (e.keyCode >= 35 && e.keyCode <= 40)) {

        return;
      }

      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57))
          && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
      }
    }
  }
}
