import { MenuOptions } from "src/app/models/menus/MenuOptions";
import { Users } from "../users/Users";

export class Options {
  constructor(
    public seq : number = 0,
    public menuOptions : MenuOptions = new MenuOptions(),
    public name : string = "",
    public engName : string = "",
    public chnName : string = "",
    public jpnName : string = "",
    public price : number = 0,
    public subPrice : number = 0,
    public order : number = 0,
    public image : string = "",
    public imageSize : number = 0,
    public users : Users = new Users(),
    public edit : boolean = false,
    public sub : boolean = false,
    public checked : boolean = false
  ) {}
}