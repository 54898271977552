import { Options } from "../models/menus/Options";

// Value Objects

export class OptionValidator {
  private invalid : boolean = true;
  private message : string;

  public check(data : Options) {

    if (data.name.length == 0) {
      this.invalid = true;
      this.message = "옵션명을 입력해 주세요.";
      return;
    }

    // if (data.price == null) {
    //   this.invalid = true;
    //   this.message = "가격을 입력해 주세요.";
    //   return;
    // }

    // if (data.price == 0) {
    //   this.invalid = true;
    //   this.message = "가격은 1이상의 숫자로 입력해 주세요.";
    //   return;
    // }
    
    this.invalid = false;
    this.message = "";
  }

  get isInvalid() : boolean {
    return this.invalid;
  }

  get errorMessage() : string {
    return this.message;
  }
}
