import { Component } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { PagerService } from "../utils/PagerService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";
import { IMyDpOptions, IMyDateModel, IMyOptions } from "mydatepicker";
import { DatePipe } from "@angular/common"

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { MobileOrderVo } from "src/app/models/orders/MobileOrderVo";
import { SearchOption } from "src/app/models/SearchOption";
import { MobileOrderSummaryVo } from "../models/orders/MobileOrderSummaryVo";
import { StoreTypes } from "../models/users/StoreTypes";
import { PAY_TYPE } from "../constants/PAY_TYPE";

@Component({
  selector : "",
  templateUrl : "../views/html/Order.html",
  providers: [AJAXService, PagerService]
})
export class OrderController {
  private mobileOrderList : MobileOrderVo[] = [];
  private searchOption : SearchOption = new SearchOption();

  private datePickerStartOption: IMyOptions = {};
	private datePickerEndOption: IMyOptions = {};
  private startDate : any;
  private endDate : any;
	private today: any;

  private summary = new MobileOrderSummaryVo();
  private pager: any = {};

  constructor(
    private ajaxService : AJAXService,
    private localStorageService : LocalStorageService,
    private pagerService: PagerService,
    private datePipe : DatePipe) {

    this.init();
    this.getOrderSummary();
  }

  private init() : void {
    let year : number = +this.datePipe.transform(Date.now(), "yyyy");
    let month : number = +this.datePipe.transform(Date.now(), "MM");
    let day : number = +this.datePipe.transform(Date.now(), "dd");

    this.today = {
			date: {
				year: year,
				month: month,
				day: day
			}
		}

		this.startDate = {
			date: {
				year: year,
				month: month,
				day: 1
			},
			jsdate: Date.now()
		};

		this.endDate = {
			date: {
				year: year,
				month: month,
				day: day
			},
			jsdate: Date.now()
    };
    
    this.datePickerStartOption = {
			width: "130px",
			height: "40px",
			dateFormat: "yyyy-mm-dd",
			showClearDateBtn: false,
			editableDateField: false,
			disableSince: {year: 0, month: 0, day: 0}

		};
		this.datePickerEndOption = {
			width: "130px",
			height: "40px",
			dateFormat: "yyyy-mm-dd",
			showClearDateBtn: false,
			editableDateField: false,
			disableUntil: {year: 0, month: 0, day: 0},
			disableSince: {year: 0, month: 0, day: 0}
		};

		// set startDate disableSince
		let d = new Date();
    d.setDate(d.getDate() + 1);
    let copy = this.getCopyOfOptions(this.datePickerStartOption);
    copy.disableSince = { year: d.getFullYear(), 
                          month: d.getMonth() + 1, 
                          day: d.getDate() };
		this.datePickerStartOption = copy;

		// set endDate disableSince
    copy = this.getCopyOfOptions(this.datePickerEndOption);
    copy.disableSince = { year: d.getFullYear(), 
                          month: d.getMonth() + 1, 
                          day: d.getDate() };
		this.datePickerEndOption = copy;

		// set endDate disableUntil
    d = new Date();
    d.setDate(1);
    d.setDate(d.getDate() -1);
		copy = this.getCopyOfOptions(this.datePickerEndOption);
    copy.disableUntil = { year: d.getFullYear(), 
          month: d.getMonth() + 1, 
          day: d.getDate() };
		this.datePickerEndOption = copy;
  }

  private setPage(page: number) {
    console.log(page);
    this.pager = {};

    if (page < 1 || page > this.pager.totalPages) {
        return;
    }

    // get pager object from service
    this.pager = this.pagerService.getPager(this.summary.totalCount, page);
  }

  private onStartDateChanged(event : IMyDateModel) {
    let copy = this.getCopyOfOptions(this.datePickerEndOption);
		let d = event.jsdate;
		d.setDate(d.getDate() - 1);
        copy.disableUntil = { year: d.getFullYear(), 
							month: d.getMonth() + 1, 
							day: d.getDate() };
		this.datePickerEndOption = copy;
		console.log(this.datePickerEndOption);

    // this.startDate = event.date;
    // let startDate : number = +("" + event.date.year + event.date.month + event.date.day);
    // let endDate : number = +("" + this.endDate.year + this.endDate.month + this.endDate.day);

    // if (startDate > endDate) {
    //   this.startDate = this.endDate;
    // } else {
    //   this.startDate = event.date;
    // }
  }

  private onEndDateChanged(event : IMyDateModel) {
    let d = event.jsdate;
		d.setDate(d.getDate() + 1);
		let copy = this.getCopyOfOptions(this.datePickerStartOption);
		copy.disableSince = { year: d.getFullYear(), 
								month: d.getMonth() + 1, 
								day: d.getDate() };
    this.datePickerStartOption = copy;
    
    // this.endDate = event.date;

    // let startDate : number = +("" + this.startDate.year + this.startDate.month + this.startDate.day);
    // let endDate : number = +("" + event.date.year + event.date.month + event.date.day);

    // if (endDate < startDate) {
    //   this.endDate = this.startDate;
    // } else {
    //   this.endDate = event.date;
    // }
  }

  private getOrderData(page : number) : void {
    this.searchOption.startDate = this.startDate.date.year + "-" + this.startDate.date.month + "-" + this.startDate.date.day;
    this.searchOption.endDate = this.endDate.date.year + "-" + this.endDate.date.month + "-" + this.endDate.date.day;

    if (this.pager.pages != null) {
			this.searchOption.pageNumber = page;
			this.searchOption.pageSize = this.pager.pageSize;
		}

    this.ajaxService
      .getDataWithCondition(API_URL.ORDER_LIST, this.searchOption)
      .then(data => {
        let response = <CommonResponse> data;

        this.mobileOrderList = <MobileOrderVo[]> response.data;
        this.setPage(page);
      });
  }

  private getOrderSummary() : void {
    this.searchOption.startDate = this.startDate.date.year + "-" + this.startDate.date.month + "-" + this.startDate.date.day;
    this.searchOption.endDate = this.endDate.date.year + "-" + this.endDate.date.month + "-" + this.endDate.date.day;

    this.ajaxService
      .getDataWithCondition(API_URL.ORDER_SUMMARY, this.searchOption)
      .then(data => {
        let response = <CommonResponse> data;
        this.summary = <MobileOrderSummaryVo> response.data;

        this.getOrderData(1);
      });
  }

  private getPayType(payType : number) {
    switch (payType) {
      case PAY_TYPE.CARD:
        return '카드';
        break;
      case PAY_TYPE.PHONE:
        return '휴대폰결제';
        break;
      case PAY_TYPE.ACCOUNT:
        return '계좌이체';
        break;
      case PAY_TYPE.CASH:
        return '현금';
        break;
    }
  }

  // Returns copy of myOptions
  private getCopyOfOptions(option): IMyOptions {
    return JSON.parse(JSON.stringify(option));
  }
}
