export class FIELD_CODE {
  public static HQ_PRICE : number = 1;
  public static FRANCHISE_PRICE : number = 2;
  public static RECOMMAND_OPTION : number = 3;
  public static SOLDOUT_OPTION : number = 4;
  public static CATEGORY : number = 5;
  public static NAME_KOR : number = 6;
  public static NAME_ENG : number = 7;
  public static NAME_CHN : number = 11;
  public static NAME_JPN : number = 12;
  public static IMAGE_REGISTER : number = 8;
  public static IMAGE_DELETE : number = 9;
  public static DISPLAY_ORDER : number = 10;
  public static HQ_OPTION_PRICE : number = 13;
  public static FRANCHISE_OPTION_PRICE : number = 14;
  public static OPTION_NAME_KOR : number = 15;
  public static OPTION_NAME_ENG : number = 16;
  public static OPTION_NAME_CHN : number = 17;
  public static OPTION_NAME_JPN : number = 18;
  public static OPTION_IMAGE_REGISTER : number = 19;
  public static OPTION_IMAGE_DELETE : number = 20;
  public static HIDE_OPTION : number = 21;
}
