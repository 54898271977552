import { Users } from "../users/Users";

export class Categories {
  constructor(
    public seq : number = null,
    public name : string = "",
    public users : Users = new Users(),
    public processMode : number = 0,
    public code : number = 0,
    public order : number = 0,
    public regDate : string = "",
    public updateDate : string = ""
  ) {}
}
