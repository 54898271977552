import { Component } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { Users } from "src/app/models/users/Users";

@Component({
  selector : "",
  templateUrl : "../views/html/Dashboard.html",
  providers: [AJAXService]
})
export class DashboardController {
  private mode : number;
  private loginUsers : Users = new Users();

  constructor(
    private ajaxService : AJAXService,
    private localStorageService : LocalStorageService) {

    this.getUserData();
  }

  private getUserData() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.USER_GET, this.localStorageService.getLoginUser())
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.loginUsers = response.data;
        }
      });
  }

  private updateUser() : void {
    if (!this.checkValidation()) {
      return;
    }

    this.ajaxService
      .getDataWithCondition(API_URL.USER_UPDATE, this.loginUsers)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          alert("정보가 수정되었습니다.");
          this.closeSettingPopup();
        }
      });
  }

  private checkValidation() : boolean {
    if (this.loginUsers.password.length == 0) {
      alert("비밀번호를 입력해 주세요.");
      return false;
    }

    if (this.loginUsers.password != this.loginUsers.passwordConfirm) {
      alert("비밀번호를 다시 확인해 주세요.");
      return false;
    }

    if (!this.loginUsers.storeUsers.phoneAuth) {
      alert("휴대폰 번호 인증을 해주세요.");
      return false;
    }

    if (this.loginUsers.storeUsers.accountNumber.length == 0) {
      alert("계좌번호를 입력해 주세요.");
      return false;
    }

    if (!this.loginUsers.storeUsers.ppaConfirm) {
      alert("개인정보 활용 동의를 해주세요.");
      return false;
    }

    return true;
  }

  private setTimeStatistics() : void {

  }

  private setDateStatistics() : void {

  }

  private setMenuStatistics() : void {

  }

  private showSettingPopup() : void {
    this.mode = 1;
  }

  private closeSettingPopup() : void {
    this.mode = 0;
  }
}
