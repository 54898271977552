import { Routes } from "@angular/router";
import { AuthChecker } from "src/app/utils/AuthChecker";

import { LoginController } from "src/app/controllers/LoginController";
import { DashboardController } from "src/app/controllers/DashboardController";
import { OrderController } from "src/app/controllers/OrderController";
import { MenuController } from "src/app/controllers/MenuController";
import { InfoController } from "src/app/controllers/InfoController";
import { PhoneAuthController } from "../controllers/PhoneAuthController";
import { CalculateController } from "../controllers/CalculateController";
import { CalculateCompleteController } from "../controllers/CalculateCompleteController";

export const routes : Routes = [
  {
    path : "",
    component : LoginController
  },
  {
    path : "login",
    component : LoginController
  },
  {
    path : "phoneAuth",
    component : PhoneAuthController
  },
  {
    path : "dashboard",
    component : DashboardController, canActivate : [ AuthChecker ]
  },
  {
    path : "order",
    component : OrderController, canActivate : [ AuthChecker ]
  },
  {
    path : "menu",
    component : MenuController, canActivate : [ AuthChecker ]
  },
  {
    path : "info",
    component : InfoController, canActivate : [ AuthChecker ]
  },
  {
    path : "calculate",
    component : CalculateController, canActivate : [ AuthChecker ]
  },
  {
    path : "calculateComplete",
    component : CalculateCompleteController, canActivate : [ AuthChecker ]
  },
  {
    path : "**",
    component : LoginController
  }
]
