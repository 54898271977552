export class API_URL {
  // public static HOST : string = "http://192.168.0.56:9090/";
  // public static HOST : string = "http://52.79.178.78:41780/";
  public static HOST : string = "https://xn--o80bz0ambw22au6x.kr:41780/";

  public static LOGIN_AUTH : string = API_URL.HOST + "login/auth";
  public static CHECK_ID : string = API_URL.HOST + "user/checkId";
  public static CHECK_DAMDAA_ID : string = API_URL.HOST + "user/checkDamdaaId";
  public static FOODZONE_USER_LIST : string = API_URL.HOST + "user/getFoodZoneUserList";
  public static USER_GET : string = API_URL.HOST + "user/getData";
  public static USER_REGISTER : string = API_URL.HOST + "user/register";
  public static USER_UPDATE : string = API_URL.HOST + "user/update";
  public static UPLOAD_STORE_IMAGE : string = API_URL.HOST + "user/uploadStoreImage";
  public static USER_TYPE_LIST : string = API_URL.HOST + "user/getUserTypeList";
  public static AREA_LIST : string = API_URL.HOST + "area/getList";
  public static SET_STORE_ISOPEN : string = API_URL.HOST + "main/setIsOpen";
  public static UPDATE_STORE_ISOPEN : string = API_URL.HOST + "main/updateIsOpen";

  public static ORDER_LIST : string = API_URL.HOST + "order/getList";
  public static ORDER_SUMMARY : string = API_URL.HOST + "order/getSummary";

  public static CATEGORY_LIST : string = API_URL.HOST + "menu/getCategoryList";
  public static CATEGORY_SETTING : string = API_URL.HOST + "menu/setCategoryData";
  public static STOCK_SETTING : string = API_URL.HOST + "menu/setStockCount";
  public static MENU_LIST : string = API_URL.HOST + "menu/getMenuList";
  public static MENU_OPTION_GET : string = API_URL.HOST + "menu/getMenuOptionData";
  public static MENU_OPTION_GET_ONE : string = API_URL.HOST + "menu/getMenuOptionDataOne";
  public static MENU_SETTING : string = API_URL.HOST + "menu/setMenuData";
  public static SOLDOUT_HIDE_SETTING : string = API_URL.HOST + "menu/setStatus";
  public static UPLOAD_MENU_IMAGE : string = API_URL.HOST + "menu/uploadMenuImage";
  public static MENU_DELETE : string = API_URL.HOST + "menu/delete";

  public static OPTION_LIST : string = API_URL.HOST + "option/getList";
  public static OPTION_INSERT : string = API_URL.HOST + "option/insert";
  public static OPTION_UPDATE : string = API_URL.HOST + "option/update";
  public static OPTION_DELETE : string = API_URL.HOST + "option/delete";
  public static OPTION_MULTIPLE_DELETE : string = API_URL.HOST + "option/multipleDelete";
  public static OPTION_DETAIL_INSERT : string = API_URL.HOST + "option/insertDetail";
  public static OPTION_DETAIL_UPDATE : string = API_URL.HOST + "option/updateDetail";
  public static OPTION_DETAIL_DELETE : string = API_URL.HOST + "option/deleteDetail";
  public static UPLOAD_OPTION_IMAGE : string = API_URL.HOST + "option/uploadImage";

  public static CALCULATE_LIST : string = API_URL.HOST + "calculate/getList";
  public static CALCULATE_SUMMARY : string = API_URL.HOST + "calculate/summary";
  public static CALCULATE_COMPLETE_LIST : string = API_URL.HOST + "calculate/complete/getList";
  public static CALCULATE_COMPLETE_ORDER_LIST : string = API_URL.HOST + "calculate/complete/getOrderList";
  public static CALCULATE_COMPLETE_PAGE_DATA : string = API_URL.HOST + "calculate/complete/pageData";
}
