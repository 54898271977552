// Value Objects
import { MenuOptions } from "./MenuOptions";
import { Menus } from "./Menus";

export class MenusMenuOptions {
  constructor(
    public menus : Menus = new Menus(),
    public menuOptions : MenuOptions = new MenuOptions(),
    public order : number = 1,
    public required : boolean = false,
    public show : boolean = false
  ) {}
}
