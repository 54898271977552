import { Categories } from "src/app/models/menus/Categories";
import { StoreUsers } from "src/app/models/users/StoreUsers";
import { Users } from "../users/Users";
import { MenusMenuOptions } from "./MenusMenuOptions";

export class Menus {
  constructor(
    public seq : number = null,
    public name : string = "",
    public categories : Categories = new Categories(),
    public description : string = "",
    public order : number = 0,
    public price : number = 0,
    public image : string = "",
    public imageSize : number = null,
    public recommand : boolean = false,
    public soldOut : boolean = false,
    public hide : boolean = false,
    public stock : number = 0,
    public stdStock : number = 0,
    public regDate : string = "",
    public updateDate : String = "",
    public users : Users = new Users(),
    public menuOptionList : MenusMenuOptions[] = new Array<MenusMenuOptions>(),
    public checked : boolean = false,
    public discountPrice : number = 0,
    public useDiscount : boolean = false,
    public discount : number = 0
  ) {}
}
