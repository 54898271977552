import { Component, ChangeDetectorRef } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { PagerService } from "../utils/PagerService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";
import { IMyDpOptions, IMyDateModel, IMyOptions } from "mydatepicker";
import { DatePipe } from "@angular/common"

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { MobileOrderVo } from "src/app/models/orders/MobileOrderVo";
import { SearchOption } from "src/app/models/SearchOption";
import { CalculateOrderVo } from "../models/orders/CalculateOrderVo";
import { CalculateSummaryVo } from "../models/orders/CalculateSummaryVo";

@Component({
	selector: "",
	templateUrl: "../views/html/Calculate.html",
	providers: [AJAXService, PagerService]
})
export class CalculateController {
	private calculateOrderList: CalculateOrderVo[] = [];
	private calculateSummary: CalculateSummaryVo = new CalculateSummaryVo();
	private searchOption: SearchOption = new SearchOption();

	private datePickerStartOption: IMyOptions = {};
	private datePickerEndOption: IMyOptions = {};
  	private startDate : any;
  	private endDate : any;
	private today: any;

	private maxItemSize: number = 0;
    private pager: any = {};
    // private pagedItems: any[];

	constructor(
		private ajaxService: AJAXService,
		private localStorageService: LocalStorageService,
		private pagerService: PagerService,
		private datePipe: DatePipe,
		private chRef: ChangeDetectorRef) {

		this.init();
		this.getCalculateSummary();
	}

	private init(): void {
		let year: number = +this.datePipe.transform(Date.now(), "yyyy");
		let month: number = +this.datePipe.transform(Date.now(), "MM");
		let day: number = +this.datePipe.transform(Date.now(), "dd");

		this.today = {
			date: {
				year: year,
				month: month,
				day: day
			}
		}

		this.startDate = {
			date: {
				year: year,
				month: month,
				day: 1
			},
			jsdate: Date.now()
		};

		this.endDate = {
			date: {
				year: year,
				month: month,
				day: day
			},
			jsdate: Date.now()
    };
    
    this.datePickerStartOption = {
		width: "130px",
		height: "40px",
		dateFormat: "yyyy-mm-dd",
		showClearDateBtn: false,
		editableDateField: false,
		disableSince: {year: 0, month: 0, day: 0}

	};
	this.datePickerEndOption = {
		width: "130px",
		height: "40px",
		dateFormat: "yyyy-mm-dd",
		showClearDateBtn: false,
		editableDateField: false,
		disableUntil: {year: 0, month: 0, day: 0},
		disableSince: {year: 0, month: 0, day: 0}
	};

	// set startDate disableSince
	let d = new Date();
    d.setDate(d.getDate() + 1);
    let copy = this.getCopyOfOptions(this.datePickerStartOption);
    copy.disableSince = { year: d.getFullYear(), 
                          month: d.getMonth() + 1, 
                          day: d.getDate() };
	this.datePickerStartOption = copy;

	// set endDate disableSince
    copy = this.getCopyOfOptions(this.datePickerEndOption);
    copy.disableSince = { year: d.getFullYear(), 
                          month: d.getMonth() + 1, 
                          day: d.getDate() };
	this.datePickerEndOption = copy;

	// set endDate disableUntil
    d = new Date();
    d.setDate(1);
    d.setDate(d.getDate() -1);
	copy = this.getCopyOfOptions(this.datePickerEndOption);
    copy.disableUntil = { year: d.getFullYear(), 
						month: d.getMonth() + 1, 
						day: d.getDate() };
	this.datePickerEndOption = copy;
	}

	private setPage(page: number) {
		console.log(page);
        this.pager = {};
        // this.pagedItems = [];

        if (page < 1 || page > this.pager.totalPages) {
            return;
        }

        // get pager object from service
        this.pager = this.pagerService.getPager(this.maxItemSize, page);

        // get current page of items
        // this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

	private onStartDateChanged(event: IMyDateModel) {
		let copy = this.getCopyOfOptions(this.datePickerEndOption);
		let d = event.jsdate;
		d.setDate(d.getDate() - 1);
        copy.disableUntil = { year: d.getFullYear(), 
							month: d.getMonth() + 1, 
							day: d.getDate() };
		this.datePickerEndOption = copy;

		return;

		console.log(event);
		this.startDate = event.date;

		let startDate: number = +("" + event.date.year + this.setZeroNumber(event.date.month) + this.setZeroNumber(event.date.day));
		let endDate: number = +("" + this.endDate.date.year + this.setZeroNumber(this.endDate.date.month) + this.setZeroNumber(this.endDate.date.day));

		if (startDate > endDate ) {
			this.startDate = this.endDate;
		// } else if (Math.abs(endDate - startDate) > 14) {
		// 	alert("조회기간은 2주 이내로 선택해주세요");
		// 	this.startDate = this.endDate;
		} else {
			this.startDate = event.date;
		}
		this.chRef.detectChanges();
	}

	private onEndDateChanged(event: IMyDateModel) {
		// set startDate disableSince
		let d = event.jsdate;
		d.setDate(d.getDate() + 1);
		let copy = this.getCopyOfOptions(this.datePickerStartOption);
		copy.disableSince = { year: d.getFullYear(), 
								month: d.getMonth() + 1, 
								day: d.getDate() };
		this.datePickerStartOption = copy;

		return; 
		
		console.log(event);
		this.endDate = event.date;

		let startDate: number = +("" + this.startDate.date.year + this.setZeroNumber(this.startDate.date.month) + this.setZeroNumber(this.startDate.date.day));
		let endDate: number = +("" + event.date.year + this.setZeroNumber(event.date.month) + this.setZeroNumber(event.date.day));

		console.log(this.endDate);

		if (endDate < startDate) {
			this.endDate = this.startDate;
		// } else if (Math.abs(endDate - startDate) > 14) {
		// 	alert("조회기간은 2주 이내로 선택해주세요");
		// 	this.endDate = this.startDate;
		} else {
			this.endDate = event.date;
		}
		this.chRef.detectChanges();
	}

	private getCalculateData(page : number): void {
		this.searchOption.startDate = this.startDate.date.year + "-" + this.setZeroNumber(this.startDate.date.month) + "-" + this.setZeroNumber(this.startDate.date.day);
		this.searchOption.endDate = this.endDate.date.year + "-" + this.setZeroNumber(this.endDate.date.month) + "-" + this.setZeroNumber(this.endDate.date.day);

		if (this.pager.pages != null) {
			this.searchOption.pageNumber = page;
			this.searchOption.pageSize = this.pager.pageSize;
		}
		
		this.ajaxService
			.getDataWithCondition(API_URL.CALCULATE_LIST, this.searchOption)
			.then(data => {
				let response = <CommonResponse>data;
				this.calculateOrderList = <any[]>response.data;

				this.setPage(page);
			});
	}

	private getCalculateSummary(): void {
		this.searchOption.startDate = this.startDate.date.year + "-" + this.setZeroNumber(this.startDate.date.month) + "-" + this.setZeroNumber(this.startDate.date.day);
		this.searchOption.endDate = this.endDate.date.year + "-" + this.setZeroNumber(this.endDate.date.month) + "-" + this.setZeroNumber(this.endDate.date.day);

		this.ajaxService
			.getDataWithCondition(API_URL.CALCULATE_SUMMARY, this.searchOption)
			.then(data => {
				let response = <CommonResponse>data;
				this.calculateSummary = <CalculateSummaryVo> response.data;

				this.maxItemSize = response.maxDataSize;
				this.getCalculateData(1);
			});
	}

	private setZeroNumber(number) {
		if (number < 10) {
			return "0" + number;
		} else {
			return number;
		}
	}

	// Returns copy of myOptions
	private getCopyOfOptions(option): IMyOptions {
		return JSON.parse(JSON.stringify(option));
	}
}
