import { Component, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";
import { FormGroup, FormControl, Validators } from "@angular/forms";

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { StoreUsers } from "src/app/models/users/StoreUsers";
import { Users } from "../models/users/Users";
import { PAY_URL } from "../constants/PAY_URL";
import { Areas } from "../models/users/Areas";

const POPUP_MODE = {
    CLOSE: 0,
    JOIN: 1,
    SELECT_JOIN_TYPE: 2,
    DAMDAA_USER: 3
}
@Component({
    selector: "",
    templateUrl: "../views/html/Login.html",
    styleUrls: ["../views/css/Login.css"],
    providers: [AJAXService]
})
export class LoginController {
    readonly POPUP_MODE = {
        CLOSE: 0,
        JOIN: 1,
        SELECT_JOIN_TYPE: 2,
        DAMDAA_USER: 3
    }
    private mode: number;
    public joinForm : FormGroup;
    private loginUsers: Users = new Users();
    private joinUsers: Users = new Users();
    private isValidId: boolean = false;
    private isPhoneAuth: boolean = false;
    private isDamdaaUser: boolean = false;
    private areaList: Areas[] = [];
    private phoneAuthText: string = "";
    
    private damdaaUser: string = "";
    private damdaaPassword: string = "";

    private allChecked : boolean = false;
    private useChecked : boolean = false;
    private infoChecked : boolean = false;
    private validator : boolean = false;

    constructor(
        private ajaxService: AJAXService,
        private localStorageService: LocalStorageService,
        private zone: NgZone,
        private router: Router) {

        window["completePhoneAuth"] = {
            zone: this.zone,
            componentFn: (phoneNumber) => this.setPhoneNumber(phoneNumber),
            component: this
        }

        this.init();
    }

    private init(): void {
        this.phoneAuthText = "휴대폰 본인 인증";
        this.getAreaList();
        this.setJoinForm();
    }

    private getAreaList() : void {
        this.ajaxService
            .getData(API_URL.AREA_LIST)
            .then(data => {
                let response = <CommonResponse> data;
                this.areaList = <Areas[]> response.data;
                console.log(this.areaList);
            });
    }

    private setJoinForm() {
        this.joinForm = new FormGroup ({
            "id" : new FormControl("", Validators.pattern("[A-Za-z0-9]{3,10}")),
            "password" : new FormControl("", Validators.pattern("[A-Za-z0-9$@$!%*#?&]{8,20}")),
            "passwordConfirm" : new FormControl("", Validators.pattern("[A-Za-z0-9$@$!%*#?&]{8,20}")),
            "phone" : new FormControl("", Validators.pattern("[0-9]{10,11}")),
            "ppaConfirm" : new FormControl(false, Validators.required),
            "shopName" : new FormControl("", Validators.required),
            "ownerName" : new FormControl("", Validators.required),
            "area" : new FormControl(0, Validators.required)
        });
    }

    private login(): void {
        this.ajaxService
            .getDataWithCondition(API_URL.LOGIN_AUTH, this.loginUsers)
            .then(data => {
                let response = <CommonResponse>data;

                if (response.result) {
                    this.localStorageService.setData("token", response.message);
                    this.localStorageService.setData("user", this.loginUsers.id);
                    this.localStorageService.setData("type", String(this.loginUsers.userTypes.seq));

                    this.router.navigateByUrl("/dashboard");
                } else {
                    alert("ID(사업자번호) 또는 비밀번호를 확인해 주세요.");
                }
            });
    }

    private checkId(): void {
        if (this.joinForm.controls.id.invalid) {
            alert("사업자등록번호(숫자 10자리)를 입력해 주세요.");
            return;
        }

        this.joinUsers.id = this.joinForm.value.id;

        this.ajaxService
            .getDataWithCondition(API_URL.CHECK_ID, this.joinUsers)
            .then(data => {
                let response = <CommonResponse>data;

                if (response.result) {
                    alert("사용할 수 있는 ID(사업자등록번호) 입니다.")
                    this.isValidId = true;
                } else {
                    alert("이미 사용중인 ID(사업자등록번호) 입니다.")
                }
            });
    }

    private resetValidId() {
        this.isValidId = false;
    }

    private checkValidation(): boolean {
        if (!this.isValidId) {
            alert("ID 확인을 해주세요.");
            return false;
        }

        if (this.joinForm.value.password != this.joinForm.value.passwordConfirm) {
            alert("비밀번호를 다시 확인해 주세요.");
            return false;
        }

        // if (!this.isPhoneAuth) {
        //   alert("휴대폰 번호 인증을 해주세요.");
        //   return false;
        // }

        // if (this.joinUsers.storeUsers.accountNumber.length == 0) {
        //     alert("계좌번호를 입력해 주세요.");
        //     return false;
        // }

        // if (!this.joinForm.value.ppaConfirm) {
        //     alert("개인정보 활용 동의를 해주세요.");
        //     return false;
        // }

        return true;
    }

    private register(): void {
        if (!this.checkValidation()) {
            return;
        }

        if (this.isDamdaaUser) {
            if (!window.confirm("변경하신 정보는 기존 담다페이 사이트의 정보와 함께 변경됩니다. 계속하시겠습니까?")) {
                return;
            }
        }

        this.joinUsers.id = this.joinForm.value.id;
        this.joinUsers.password = this.joinForm.value.password;
        this.joinUsers.phone = this.joinForm.value.phone;
        this.joinUsers.storeUsers.ppaConfirm = this.joinForm.value.ppaConfirm;
        this.joinUsers.shopName = this.joinForm.value.shopName;
        this.joinUsers.ownerName = this.joinForm.value.ownerName;
        this.joinUsers.areas.seq = this.joinForm.value.area;

        this.ajaxService
            .getDataWithCondition(API_URL.USER_REGISTER, this.joinUsers)
            .then(data => {
                let response = <CommonResponse>data;

                if (response.result) {
                    alert("가입 신청되었습니다. \r\n가입 승인이 될 때까지 기다려주세요.");
                    this.closeJoinPopup();
                } else {
                    if (response.message == "existPhone") {
                        alert("사용중인 휴대폰 번호입니다.");
                    } else if (response.message == "existNolineUser") {
                        alert("이미 가입된 사용자입니다.");
                    } else {
                        alert("가입 신청을 실패했습니다.");
                    }
                }
            });
    }

    private setAutoLogin(): void {

    }

    private showJoinPopup(): void {
        this.mode = POPUP_MODE.JOIN;
    }

    private closeJoinPopup(): void {
        this.isValidId = false;
        this.isPhoneAuth = false;
        this.joinUsers = new Users();
        this.isDamdaaUser = false;
        this.damdaaUser = "";
        this.damdaaPassword = "";
        this.mode = POPUP_MODE.CLOSE;
        this.setJoinForm();
    }

    private showJoinTypePopup(): void {
        this.allChecked = false;
        this.useChecked = false;
        this.infoChecked = false;
        this.validator = false;
        this.mode = POPUP_MODE.SELECT_JOIN_TYPE;
        console.log(this.mode);
    }

    private allCheck() {
        this.useChecked = this.allChecked;
        this.infoChecked = this.allChecked;
        this.validator = this.allChecked;
    }

    private checkedValidation() : void {
        if(!this.useChecked || !this.infoChecked){
            this.allChecked = false;
            this.validator = false;
            return;
        }
        // else if(!this.useChecked && this.infoChecked){
        //     this.allChecked = false;
        //     this.validator = false;
        //     return;
        // }
        // else if(this.useChecked && !this.infoChecked){
        //     this.allChecked = false;
        //     this.validator = false;
        //     return;
        // }
        else{
            this.allChecked = true;
            this.validator = true;
            return;
        }
    }
    private validation() : void {
        if(!this.useChecked && !this.infoChecked){
            alert("이용약관 과 개인정보처리에 동의해주세요.");
            return;
        }
        else if(!this.useChecked && this.infoChecked){
            alert("이용약관에 동의해주세요.");
            return;
        }
        else if(this.useChecked && !this.infoChecked){
            alert("개인정보처리에 동의해주세요.");
            return;
        }
        else{
            return;
        }
    }
    private showCheckDamdaaUser(): void {
        this.mode = POPUP_MODE.DAMDAA_USER;
    }


    private trim(text: string) {
        return text.replace(/(^\s*)|(\s*$)/gi, "");
    }

    private loadAuthModule(): void {
        window.open(PAY_URL.PHONE_AUTH, "_blank", "width=650, height=650");
    }

    private damdaaLogin(): void {
        this.damdaaUser = this.trim(this.damdaaUser);
        this.damdaaPassword = this.trim(this.damdaaPassword);

        if (this.damdaaUser.length == 0 || this.damdaaUser == "") {
            alert("아이디를 입력해주세요.");
            return;
        }

        if (this.damdaaPassword.length == 0 || this.damdaaPassword == "") {
            alert("비밀번호를 입력해주세요.")
            return;
        }

        console.log(this.joinUsers);

        this.joinUsers.id = this.damdaaUser;
        this.joinUsers.password = this.damdaaPassword;

        this.ajaxService
            .getDataWithCondition(API_URL.CHECK_DAMDAA_ID, this.joinUsers)
            .then(data => {
                let response = <CommonResponse> data;
                let user = <Users> response.data;

                console.log(response.data);
                if (response.result) {

                    if (user.storeUsers != null) {
                        alert("이미 등록된 노라인 사용자입니다.");
                        return;
                    }

                    this.isDamdaaUser = true;
                    this.isValidId = true;
                    this.joinForm.controls.id.setValue(user.id);
                    this.joinForm.controls.shopName.setValue(user.shopName);
                    this.joinForm.controls.ownerName.setValue(user.ownerName);
                    this.joinForm.controls.area.setValue(user.areas.seq);
                    this.joinForm.controls.phone.setValue(user.phone);

                    this.joinUsers.useNoline = true;

                    // temp
                    // this.joinForm.controls.phone.setValue("01085945454");
                    // this.isPhoneAuth = true;
                    // this.phoneAuthText = "휴대폰 인증 완료";


                    alert("담다페이 사용자 정보가 확인되었습니다. 회원가입을 진행해주세요.");
                    this.showJoinPopup();
                } else {
                    alert("담다페이에 등록된 정보가 없습니다.");
                }
            });
    }

    public setPhoneNumber(phoneNumber) {
        this.joinUsers.phone = phoneNumber;
        this.joinForm.controls.phone.setValue(phoneNumber);
        this.isPhoneAuth = true;

        this.phoneAuthText = "휴대폰 인증 완료";
    }
}
