export class CalculateSummaryVo {
  constructor(
    public salesPrice : number = 0,
    public salesCount : number = 0,
    public cardPrice : number = 0,
    public cardCount : number = 0,
    public phonePrice : number = 0,
    public phoneCount : number = 0,
    public accountPrice : number = 0,
    public accountCount : number = 0,
    public cashPrice : number = 0,
    public cashCount : number = 0,
    public payFeePrice : number = 0,
    public msgFeePrice : number = 0,
    public msgFeeCount : number = 0,
    public calculatedPrice : number = 0
  ) {}
}
