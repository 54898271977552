import { Areas } from "src/app/models/users/Areas";
import { StoreTypes } from "src/app/models/users/StoreTypes";

export class StoreUsers {
  constructor(
    public id : string = "",
    public storeTypes : StoreTypes = new StoreTypes(),
    // public phone1 : string = "010",
    // public phone2 : string = "",
    // public phone3 : string = "",
    public phoneAuthNumber : number = null,
    public phoneAuth : boolean = false,
    public accountNumber : string = "",
    public ppaConfirm : boolean = false,
    public joinAuth : boolean = false,
    public opStartHour : string = "00",
    public opStartMin : string = "00",
    public opEndHour : string = "00",
    public opEndMin : string = "00",
    public holyday : string = "",
    public storeImage : string = "",
    public storeImageSize : number = null,
    public zipCode : string = "",
    public address : string = "",
    public description : string = "",
    public fcmId : string = "",
    public elapsedTime : number = 20, 
    public originInfo : string = "",
    public latitude : string = "",
    public longitude : string = "",
    public businessNumber : string = "",
    public open : boolean = false
  ) {}
}
