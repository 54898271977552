import { Component } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";

import { API_URL } from "src/app/constants/API_URL";
import { FilePath } from "src/app/constants/FilePath";
import { CommonResponse } from "src/app/models/CommonResponse";
import { StoreUsers } from "src/app/models/users/StoreUsers";
import { Categories } from "src/app/models/menus/Categories";
import { Menus } from "src/app/models/menus/Menus";
import { MenuOptions } from "src/app/models/menus/MenuOptions";
import { FileData } from "src/app/models/menus/FileData";
import { Users } from "../models/users/Users";
import { Options } from "../models/menus/Options";
import { USER_TYPE } from "../constants/USER_TYPE";
import { MenuOptionValidator } from "./MenuOptionValidator";
import { OptionValidator } from "./OptionValidator";
import { FIELD_CODE } from "../constants/FIELD_CODE";
import { MenusMenuOptions } from "../models/menus/MenusMenuOptions";

enum MODE  {
  CLOSE,
  MENU,
  CATEGORY,
  OPTION,
  OPTION_REGISTER,
  OPTION_EDIT,
  OPTION_DELETE,
  OPTION_DETAIL_REGISTER,
  OPTION_DETAIL_EDIT,
  OPTION_DETAIL_DELETE,
  OPTION_ALERT,
  OPTION_LIST
}

@Component({
  selector : "",
  templateUrl : "../views/html/Menu.html",
  styleUrls: ["../views/css/Menu.css"],
  providers: [AJAXService]
})
export class MenuController {
  readonly MODE = MODE;
  readonly FIELD_CODE = FIELD_CODE;
  private mode : MODE;
  private prevMode : MODE;  

  private loginUsers : Users = new Users();

  private categoryList : Categories[] = [];
  private tempCategoryList : Categories[] = [];
  private selectedCategorySeq : number = null;

  private menuList : Menus[] = [];
  private regMenu : Menus = new Menus();
  private menuImageFile : any = null;

  private optionValidator : MenuOptionValidator = new MenuOptionValidator();
  private optionDetailValidator : OptionValidator = new OptionValidator();

  private optionList : MenuOptions[] = [];
  private optionListChecked : boolean = false;
  private option : MenuOptions = new MenuOptions();
  private isOptionMultipleDelete : boolean = false;
  private optionDetail : Options = new Options();
  private selectedOption : MenuOptions = new MenuOptions();
  private optionImage : any = null;
  private optionFile : any = null;

  private popMessage : string = "";

  constructor(
    private ajaxService : AJAXService,
    private localStorageService : LocalStorageService) {

    this.getUserData();
    this.getCategoryData();
  }

  private getUserData() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.USER_GET, this.localStorageService.getLoginUser())
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.loginUsers = response.data;
        }
      });
  }

  private getCategoryData() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.CATEGORY_LIST, this.localStorageService.getLoginUser())
      .then(data => {
        let response = <CommonResponse> data;

        this.categoryList = <Categories[]> response.data;
        if (this.categoryList.length > 0 ) {
          this.selectedCategorySeq = this.categoryList[0].seq;
          this.getMenuData();
        }
      });
  }

  private showCategoryPopup() : void {
    this.tempCategoryList = this.categoryList.map(item => Object.assign({}, item));
    this.mode = MODE.CATEGORY;
    console.log(this.mode);
  }

  private filteredTempCategoryList() : Categories[] {
    return this.tempCategoryList.filter(
      item => item.processMode != 3
    );
  }

  private addCategoryItem() : void {
    let newCategory : Categories = new Categories();
    newCategory.processMode = 1;
    newCategory.users = this.localStorageService.getLoginUser();

    this.tempCategoryList.push(newCategory);
  }

  private onCategoryNameChanged(seq : number) : void {
    // this.tempCategoryList.forEach(function (item) {
    //   if (item.seq == seq) {
    //     item.processMode = 2;
    //   }
    // });
    console.log(this.tempCategoryList[seq]);
    if (this.tempCategoryList[seq].seq != null) {
      this.tempCategoryList[seq].processMode = 2; 
    }
  }

  private deleteItem(seq : number) : void {
    // this.tempCategoryList.forEach(function (item) {
    //   if (item.seq == seq) {
    //     item.processMode = 3;
    //   }
    // });

    this.tempCategoryList[seq].processMode = 3;
  }

  private setCategoryData() : void {
    let checkValidation = true;
    this.tempCategoryList.forEach(category => { 
      category.name = category.name.trim();
      if (category.name.length == 0) {
        checkValidation = false;
      }
    });

    if (!checkValidation) {
      alert("카테고리명을 입력하세요.");
      return;
    }

    this.ajaxService
      .getDataWithCondition(API_URL.CATEGORY_SETTING, this.tempCategoryList)
      .then(data => {
        this.getCategoryData();
        this.closePopup();
      });
  }

  private changeCategory(categorySeq : number) : void {
    this.selectedCategorySeq = categorySeq;
    this.getMenuData();
  }

  private getMenuData() : void {
    let category : Categories = new Categories();
    category.seq = this.selectedCategorySeq;

    this.ajaxService
      .getDataWithCondition(API_URL.MENU_LIST, category)
      .then(data => {
        let response = <CommonResponse> data;
        this.menuList = <Menus[]> response.data;
        console.log("menuList");
        console.log(this.menuList);
      });
  }

  private getMenuImage(image : string) : string {
    if (image != null && image.length > 0) {
      return FilePath.MENU_IMAGE + image;
    }
  }

  private setStockCount() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.STOCK_SETTING, this.menuList)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.getMenuData();
        }
      });
  }

  private showMenuRegisterPopup() : void {
    console.log('show menu register popup');

    console.log(this.selectedCategorySeq);

    if (!this.selectedCategorySeq) {
      alert("카테고리를 먼저 등록해주세요.");
      return;
    }
    
    this.regMenu = new Menus();

    let category : Categories = new Categories();
    category.seq = this.selectedCategorySeq;
    this.regMenu.categories = category;
    this.regMenu.users = this.loginUsers;
    this.menuImageFile = null;

    this.mode = MODE.MENU;

    console.log(this.regMenu);
    console.log(this.menuImageFile);
    console.log('---------------');
  }

  private showMenuUpdatePopup(menu : Menus) : void {
    console.log('show menu update popup');
    console.log(menu);
    this.regMenu = menu;
    this.mode = MODE.MENU;

    this.ajaxService
      .getDataWithCondition(API_URL.MENU_OPTION_GET, menu)
      .then(data => {
        console.log(data);
        let response = <CommonResponse> data;

        this.regMenu = response.data;
        this.mode = MODE.MENU;
        this.menuImageFile = null;

        console.log(this.regMenu);
        console.log(this.menuImageFile);
        console.log('-----------------');
      });
  }

  // private addOptionItem() : void {
  //   if (this.regMenu.menuOptionList.length == 10) {
  //     return;
  //   }
  //   let menuOptions : MenuOptions = new MenuOptions();
  //   let options : Options = new Options();
  //   menuOptions.users = this.loginUsers;
  //   options.users = this.loginUsers;
  //   menuOptions.optionList.push(options);
  //   this.regMenu.menuOptionList.push(menuOptions);
  // }

  // private addOptionDetailItem(optIdx : number) : void {
  //   if (this.regMenu.menuOptionList[optIdx].optionList.length == 5) {
  //     return;
  //   }
  //   let option = new Options();
  //   option.users = this.loginUsers;
  //   this.regMenu.menuOptionList[optIdx].optionList.push(option);
  // }

  // public deleteMenuOptionItem(menu : Menus, index : number) : void {
  //   if (this.isParentMenuOption(menu.menuOptionList[index])) {
  //     return;
  //   }
  //   menu.menuOptionList.splice(index, 1);
  // }

  public deleteOptionDetailItem(menuOption : MenuOptions, index : number) : void {
    if (menuOption.optionList.length == 1 || this.isParentOption(menuOption.optionList[index])) {
      return;
    }
    menuOption.optionList.splice(index, 1);
    
    // this.checkMenuValidation();
  }

  public isParentMenuOption(menuOption : MenuOptions) {
    if (this.loginUsers.userTypes.seq == USER_TYPE.FRANCHISE 
        && (menuOption.sub || (!menuOption.sub && menuOption.users.id != this.loginUsers.id))) {
      return true;
    }
    return false;
  }

  public isParentOption(option : Options) {
    if (this.loginUsers.userTypes.seq == USER_TYPE.FRANCHISE 
        && (option.sub || (!option.sub && option.users.id != this.loginUsers.id))) {
      return true;
    }
    return false;
  }

  public initMenuOptionToParent(menuOption : MenuOptions) : void {
    console.log(menuOption);
    
    this.ajaxService
      .getDataWithCondition(API_URL.MENU_OPTION_GET_ONE, menuOption)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          let data = response.data;
          let parentMenuOption : MenuOptions = <MenuOptions> data;
          let parentOptionList : Options[] = [];
          console.log(parentMenuOption); 
          parentMenuOption.optionList.forEach(option => {
            if (option.users.id == parentMenuOption.users.id) {
              parentOptionList.push(option);
            }
          });
          menuOption.optionList = parentOptionList;
          
          // this.checkMenuValidation();
        }
      });
  }

  private fileEvent(event : any) : void {
    console.log('file event-------');
    console.log(event.target.files[0]);
    this.menuImageFile = event.target.files[0];
    this.regMenu.image = this.menuImageFile.name;
  }

  private fileUpload() : void {
    console.log(this.menuImageFile);
    
    if (this.menuImageFile == null) {
      this.setMenuData();
    } else {
      let formData : FormData = new FormData();
      formData.append("file", this.menuImageFile);

      let xhr = new XMLHttpRequest();
      var self = this;

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          let responseText = xhr.responseText;
          let response : CommonResponse = <CommonResponse> JSON.parse(responseText);

          if (response.result) {
            let fileData : FileData = <FileData> response.data;

            self.regMenu.image = fileData.fileName;
            self.regMenu.imageSize = fileData.fileSize;
            self.setMenuData();
          }
        }
      }

      xhr.open("POST", API_URL.UPLOAD_MENU_IMAGE, true);
      xhr.setRequestHeader("jwt-auth-token", localStorage.getItem("token"));
      xhr.send(formData);
    }
  }

  private setMenuData() : void {
    this.regMenu.price = Number(this.regMenu.price);
    console.log(this.regMenu);
    if (!this.regMenu.categories.seq) {
      alert("카테고리를 먼저 등록해주세요.");
      return;
    }
    this.ajaxService
      .getDataWithCondition(API_URL.MENU_SETTING, this.regMenu)
      .then(data => {
        this.getMenuData();
        this.closePopup();
      });
  }

  private changeSoldoutStatus(menu : Menus) : void {
    menu.soldOut = !menu.soldOut;

    console.log(menu);

    this.ajaxService
      .getDataWithCondition(API_URL.SOLDOUT_HIDE_SETTING, menu)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.getMenuData();
        }
      });
  }

  private changeHideStatus(menu : Menus) : void {
    menu.hide = !menu.hide;

    this.ajaxService
      .getDataWithCondition(API_URL.SOLDOUT_HIDE_SETTING, menu)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.getMenuData();
        }
      });
  }

  private deleteMenu(menu : Menus) : void {
    if (!window.confirm("삭제된 메뉴는 복구할 수 없습니다. 메뉴를 삭제하시겠습니까?")) {
      return;
    }

    this.ajaxService
      .getDataWithCondition(API_URL.MENU_DELETE, menu)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.getMenuData();
        }
      });
  }

  private closePopup(isOption: boolean = false) : void {
    if (isOption) {
      this.mode = MODE.OPTION;
    } else {
      this.mode = MODE.CLOSE;
    }
  }

  
  // 20190726 Option
  private isShowOptionPopup() : boolean {
    switch (this.mode) {
      case MODE.OPTION:
      case MODE.OPTION_REGISTER:
      case MODE.OPTION_EDIT:
      case MODE.OPTION_DELETE:
      case MODE.OPTION_DETAIL_REGISTER:
      case MODE.OPTION_DETAIL_EDIT:
      case MODE.OPTION_DETAIL_DELETE:
      case MODE.OPTION_ALERT:
        return true;
      default:
        return false;
    }
  }
  private showOptionPopup() : void {
    // this.tempCategoryList = this.categoryList.map(item => Object.assign({}, item));
    this.setOptionList();
    this.mode = MODE.OPTION;
    console.log(this.mode);
  }

  private showOptionRegisterPopup() : void {
    this.option = new MenuOptions();

    this.optionValidator = new MenuOptionValidator();
    this.checkOptionValidation();

    this.mode = MODE.OPTION_REGISTER;
  }

  private showOptionEditPopup(data : MenuOptions) : void {
    if (!this.checkOptionRegUser(data.users.id)) {
      return;
    }

    this.option = JSON.parse(JSON.stringify(data));

    this.optionValidator = new MenuOptionValidator();
    this.checkOptionValidation();

    this.mode = MODE.OPTION_EDIT;
  }

  private showOptionDeletePopup(data : MenuOptions, isMultipleOptionDelete : boolean) : void {
    if (data != null && !this.checkOptionRegUser(data.users.id)) {
      this.popMessage = "본사에서 등록한 옵션은 삭제할 수 없습니다.";
      this.mode = MODE.OPTION_ALERT;
      return;
    }

    if (isMultipleOptionDelete) {
      let isChecked : boolean = false;

      this.optionList.forEach(item => {
        if (item.checked) {
          isChecked = true;
        }
      });

      if (!isChecked) {
        this.popMessage = "삭제할 옵션을 선택해 주세요.";
        this.mode = MODE.OPTION_ALERT;
        return;
      }
    } else {
      if (data == null) {
        return;
      }

      this.option = data;
    }
    
    console.log(this.loginUsers.userTypes.seq);
    console.log(this.optionDetail);
    if (this.loginUsers.userTypes.seq == USER_TYPE.FRANCHISE) {
      if (this.option.users.userTypes.seq == USER_TYPE.HEADQUATER) {
        this.popMessage = "본사에서 등록한 옵션은 삭제할 수 없습니다.";
        this.mode = MODE.OPTION_ALERT;
        return;
      }
    }

    this.isOptionMultipleDelete = isMultipleOptionDelete;
    this.mode = MODE.OPTION_DELETE;
  }

  private showOptionDetailRegisterPopup() : void {
    console.log(this.selectedOption);
    console.log(this.optionList);
    if (this.selectedOption.seq == 0 || this.optionList.length == 0) {
      alert("왼쪽 리스트에서 옵션을 선택하거나 등록해주세요.");
      return;
    }

    if (!this.checkOptionRegUser(this.selectedOption.users.id)) {
      alert("본사에서 등록한 옵션에는 구성을 추가할 수 없습니다.");
      return; 
    }

    this.optionImage = null;
    this.optionFile = null;

    this.optionDetail = new Options();

    this.optionDetailValidator = new OptionValidator();
    this.checkOptionDetailValidation();

    this.mode = MODE.OPTION_DETAIL_REGISTER;
  }

  private showOptionDetailEditPopup(data : Options) : void {
    this.optionDetail = JSON.parse(JSON.stringify(data));
    this.optionImage = null;
    this.optionFile = null;
 
    if (this.optionDetail.image != null && this.optionDetail.image.length > 0) {
      this.optionImage = this.getOptionImage(true, this.optionDetail.image);
    }

    this.optionDetailValidator = new OptionValidator();
    this.checkOptionDetailValidation();


    this.mode = MODE.OPTION_DETAIL_EDIT;
  }

  private showOptionDetailDeletePopup(data : Options) : void {
    if (data == null) {
      return;
    }

    this.optionDetail = data;

    if (this.loginUsers.userTypes.seq == USER_TYPE.FRANCHISE) {
      if (this.optionDetail.users.userTypes.seq == USER_TYPE.HEADQUATER) {
        this.popMessage = "본사에서 등록한 옵션 구성은 삭제할 수 없습니다.";
        this.mode = MODE.OPTION_ALERT;
        return;
      }
    }

    this.mode = MODE.OPTION_DETAIL_DELETE;
  }

  private setOptionList() : void {
    this.ajaxService
    .getDataWithCondition(API_URL.OPTION_LIST, this.loginUsers)
    .then(data => {
      this.optionList = <MenuOptions[]> data;

      if (this.optionList.length > 0) {
        if (this.selectedOption.seq == 0) {
          this.selectedOption = this.optionList[0];
        } else {
          this.optionList.forEach(option => {
            if (this.selectedOption.seq == option.seq) {
              this.selectedOption = option;
            }
          });
        }
      }
    });
  }

  private checkOptionValidation() : void {
    this.optionValidator.check(this.option);
  }

  private checkOptionDetailValidation() : void {
    this.optionDetailValidator.check(this.optionDetail);
  }

  private setOptionDetailList(data: MenuOptions) {
    this.selectedOption = data;
  }

  private checkOptionRegUser(userId : string) {
    if (userId == this.loginUsers.id) {
      return true;
    }
    return false;
  }

  private multipleCheckOption() : void {
    if (this.optionList.length > 0) {
      this.optionList.forEach(item => {
        item.checked = this.optionListChecked;
      });
    }
  }

  private getOptionImage(isInDialog : boolean, image : string) : string {
    if (isInDialog && this.optionImage != null) {
      return this.optionImage;
    }

    if (image != null && image.length > 0) {
      return FilePath.OPTION_IMAGE + image;
    }

    return null;
  }

  private registerMenuOption() : void {
    if (this.optionValidator.isInvalid) {
      return;
    }

    let requestUrl : string
      = (this.mode == MODE.OPTION_REGISTER)? API_URL.OPTION_INSERT : API_URL.OPTION_UPDATE;

    this.ajaxService
      .getDataWithCondition(requestUrl, this.option)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.mode = MODE.OPTION;
          this.setOptionList();
        }
      });
  };

  protected deleteMenuOption() : void {
    if (this.isOptionMultipleDelete) {
      let optionList : MenuOptions[] = [];

      this.optionList.forEach(item => {
        if (item.checked) {
          optionList.push(item);
        }
      });

      this.ajaxService
        .getDataWithCondition(API_URL.OPTION_MULTIPLE_DELETE, optionList)
        .then(data => {
          let response = <CommonResponse> data;

          if (response.result) {
            this.setOptionList();
            this.mode = MODE.OPTION;
          }
        });
    } else {
      this.ajaxService
        .getDataWithCondition(API_URL.OPTION_DELETE, this.option)
        .then(data => {
          let response = <CommonResponse> data;

          if (response.result) {
            this.setOptionList();
            this.mode = MODE.OPTION;
          }
        });
    }
  }

  private optionFileUpload() : void {
    if (this.optionDetailValidator.isInvalid) {
      return;
    }

    if (this.optionFile == null) {
      this.registerOptionDetail();
    } else {
      let formData : FormData = new FormData();
      formData.append("file", this.optionFile);

      let xhr = new XMLHttpRequest();
      var self = this;

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          let responseText = xhr.responseText;
          let response : CommonResponse = <CommonResponse> JSON.parse(responseText);

          if (response.result) {
            let fileData : FileData = <FileData> response.data;

            self.optionDetail.image = fileData.fileName;
            self.optionDetail.imageSize = fileData.fileSize;
            self.registerOptionDetail();
          }
        }
      }

      xhr.open("POST", API_URL.UPLOAD_OPTION_IMAGE, true);
      xhr.setRequestHeader("jwt-auth-token", localStorage.getItem("token"));
      xhr.send(formData);
    }
  }

  private registerOptionDetail() : void {
    if (this.optionDetailValidator.isInvalid) {
      return;
    }
    
    let requestUrl : string
      = (this.mode == MODE.OPTION_DETAIL_REGISTER)? API_URL.OPTION_DETAIL_INSERT : API_URL.OPTION_DETAIL_UPDATE;

    // if (this.mode == MODE.OPTION_DETAIL_EDIT) {
      console.log(this.optionDetail);
      this.optionDetail.menuOptions = new MenuOptions();
      this.optionDetail.menuOptions.seq = this.selectedOption.seq;
    // }
    
    this.ajaxService
      .getDataWithCondition(requestUrl, this.optionDetail)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.mode = MODE.OPTION;
          this.setOptionList();
        } else {
          console.log("fail . . . .");
        }
      });
  };

  protected deleteOptionDetail() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.OPTION_DETAIL_DELETE, this.optionDetail)
      .then(data => {
        let response = <CommonResponse> data;

        if (response.result) {
          this.setOptionList();
          
          this.optionList.forEach(option => {
            
          });
          this.mode = MODE.OPTION;
        }
      });
  };

  private optionfileEvent(event : any) : void {
    this.optionFile = event.target.files[0];
    this.optionImage = event.target.files[0];

    let fileReader : FileReader = new FileReader();
    fileReader.onload = (event : any) => {
      this.optionImage = event.target.result;
    }

    fileReader.readAsDataURL(event.target.files[0]);
  }

  private removeOptionImage() : void {
    console.log("remove option image");
    this.optionDetail.image = null;
    this.optionDetail.imageSize = 0;
    this.optionImage = null;
    this.optionFile = null;
  }

  private removeOptionFromMenu(option : MenusMenuOptions) : void {
    this.regMenu.menuOptionList.forEach((menuOption, index) => {
      if (menuOption.menuOptions.seq == option.menuOptions.seq) {
        this.regMenu.menuOptionList.splice(index, 1);
      }
    });
  }

  public setMenuOptionList(menu : Menus) : void {
    // if (menu.menuOptionList.length == 10) {
    //   return;
    // }
    // let menuOption = this.getInititalMenuOptionData();
    // menuOption.users = this.loginUsers;
    // menu.menuOptionList.push(menuOption);

    this.ajaxService
    .getDataWithCondition(API_URL.OPTION_LIST, this.loginUsers)
    .then(data => {
      this.optionList = <MenuOptions[]> data;

      this.regMenu.menuOptionList.forEach(menuOption => {
        this.optionList.forEach(option => {
          if (menuOption.menuOptions.seq == option.seq) {
            option.exist = true;
          }
        });        
      });
      this.prevMode = this.mode;
      this.mode = MODE.OPTION_LIST;
    });
  }

  private addOptionToMenu() : void {

    this.optionList.forEach(option => {
      if (option.checked) {
        let menusMenuOption = new MenusMenuOptions();
        menusMenuOption.menus.seq = this.regMenu.seq;
        menusMenuOption.menuOptions = option;
        console.log(menusMenuOption);
        this.regMenu.menuOptionList.push(menusMenuOption);
      }
    });

    this.mode = this.prevMode;
  }
  
  private isVisibleField(code : number) : boolean {
    // made in franchise
    if (this.loginUsers.userTypes.seq == 3) {
      if (this.mode == MODE.OPTION_DETAIL_REGISTER 
        || (this.mode == MODE.OPTION_DETAIL_EDIT && this.optionDetail != undefined && (this.loginUsers.id == this.optionDetail.users.id))) {
        switch (code) {
          case FIELD_CODE.FRANCHISE_OPTION_PRICE :
          case FIELD_CODE.OPTION_NAME_KOR :
          case FIELD_CODE.OPTION_NAME_ENG :
          case FIELD_CODE.OPTION_NAME_CHN :
          case FIELD_CODE.OPTION_NAME_JPN :
          case FIELD_CODE.OPTION_IMAGE_REGISTER:
          case FIELD_CODE.OPTION_IMAGE_DELETE:
            return true;
          default :
            return false;
        }
      }
    }

    // franchise
    if (this.loginUsers.userTypes.seq == 3) {
      switch (code) {
        case FIELD_CODE.FRANCHISE_PRICE :
        case FIELD_CODE.RECOMMAND_OPTION :
        case FIELD_CODE.SOLDOUT_OPTION :
        case FIELD_CODE.HIDE_OPTION :
        case FIELD_CODE.FRANCHISE_OPTION_PRICE :
          return true;
        default :
          return false;
      }
    }

    // personal
    if (this.loginUsers.userTypes.seq == 4) {
      switch (code) {
        case FIELD_CODE.HQ_PRICE :
        case FIELD_CODE.RECOMMAND_OPTION :
        case FIELD_CODE.CATEGORY :
        case FIELD_CODE.SOLDOUT_OPTION :
        case FIELD_CODE.HIDE_OPTION :
        case FIELD_CODE.NAME_KOR :
        case FIELD_CODE.NAME_ENG :
        case FIELD_CODE.NAME_CHN :
        case FIELD_CODE.NAME_JPN :
        case FIELD_CODE.IMAGE_REGISTER :
        case FIELD_CODE.IMAGE_DELETE :
        case FIELD_CODE.DISPLAY_ORDER :
        case FIELD_CODE.HQ_OPTION_PRICE :
        case FIELD_CODE.OPTION_NAME_KOR :
        case FIELD_CODE.OPTION_NAME_ENG :
        case FIELD_CODE.OPTION_NAME_CHN :
        case FIELD_CODE.OPTION_NAME_JPN :
        case FIELD_CODE.OPTION_IMAGE_REGISTER:
        case FIELD_CODE.OPTION_IMAGE_DELETE:
          return true;
        default :
          return false;
      }
    }

    return true;
  }

  private setDiscountPrice(): void{
    let price = this.regMenu.price;
    // let subPrice = this.regMenu.subPrice;

    // if (price == 0 && subPrice == 0) {
    //   this.regMenu.discountPrice = 0;
    //   this.regMenu.discount = 0;
    // } else if (price == 0 && subPrice > 0)  {
    //   if (subPrice < this.regMenu.discount) {
    //     this.regMenu.discount = subPrice;
    //   } 
    //   this.regMenu.discountPrice = subPrice - this.regMenu.discount;
      
    // } else if (price > 0 && subPrice == 0) {
    //   if (price < this.regMenu.discount) {
    //     this.regMenu.discount = price;
    //   }
    //   this.regMenu.discountPrice = price - this.regMenu.discount;
    // }
    console.log(this.regMenu.price);
    if (price == 0) {
      this.regMenu.discountPrice = 0;
      this.regMenu.discount = 0;
    } else {
      if (price < this.regMenu.discount) {
        this.regMenu.discount = price;
      }
      this.regMenu.discountPrice = price - this.regMenu.discount;
    }
    console.log("discount");
    console.log(this.regMenu.discount);
  }
}
