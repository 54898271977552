import { Component, ChangeDetectorRef } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { StoreUsers } from "src/app/models/users/StoreUsers";
import { Areas } from "src/app/models/users/Areas";
import { StoreTypes } from "src/app/models/users/StoreTypes";
import { FileData } from "src/app/models/menus/FileData";
import { Users } from "../models/users/Users";
declare var daum: any;

@Component({
  selector : "",
  templateUrl : "../views/html/Info.html",
  providers: [AJAXService]
})
export class InfoController {
    private loginUsers : Users = new Users();
    private storeImageFile : any = null;

    private areaList : Areas[] = [];
    private foodZoneList : Users[] = [];
    private foodZone : Users = new Users();
    private typeList : StoreTypes[] = [];

    private addressOptions : any = {
        class: ['button', 'white']
    }

    constructor(
        private ajaxService : AJAXService,
        private localStorageService : LocalStorageService,
        private chRef: ChangeDetectorRef) {
    
        this.getUserData();
        this.getFoodZoneList();
        this.getAreaList();
        this.getUserTypeList();
    }

    private getUserData() : void {
        this.ajaxService
            .getDataWithCondition(API_URL.USER_GET, this.localStorageService.getLoginUser())
            .then(data => {
                let response = <CommonResponse> data;

                if (response.result) {
                    this.loginUsers = response.data;
                }

                console.log(this.loginUsers);
            });
    }

    private getFoodZoneList() : void {
        this.ajaxService
            .getData(API_URL.FOODZONE_USER_LIST)
            .then(data => {
                let response = <CommonResponse> data;
                this.foodZoneList = <Users[]> response.data;

                if (this.foodZoneList.length > 0) {
                    this.foodZone.id = this.foodZoneList[0].id;
                }
            });
    }

    private getAreaFoodZoneList() : Users[] {
        // return this.foodZoneList.filter(
        //   item => item.areas.seq == this.loginUsers.areas.seq  
        // );
        return null;
    }

    private getAreaList() : void {
        this.ajaxService
            .getData(API_URL.AREA_LIST)
            .then(data => {
                let response = <CommonResponse> data;
                this.areaList = <Areas[]> response.data;
                console.log(this.areaList);
            });
    }

    private getUserTypeList() : void {
        this.ajaxService
            .getData(API_URL.USER_TYPE_LIST)
            .then(data => {
                let response = <CommonResponse> data;
                this.typeList = <StoreTypes[]> response.data;
            });
    }

    private setUserData() : void {
        if (!this.checkValidation()) {
            return;
        }
        console.log('set userdata');
        // this.loginUsers.parent = this.foodZone;
        // this.loginUsers.areas.seq = +this.loginUsers.areas.seq;
        this.loginUsers.storeUsers.storeTypes.seq = +this.loginUsers.storeUsers.storeTypes.seq;
        this.loginUsers.zipCode = this.loginUsers.storeUsers.zipCode;
        this.loginUsers.storeUsers.address = this.loginUsers.address;

        this.ajaxService
            .getDataWithCondition(API_URL.USER_UPDATE, this.loginUsers)
            .then(data => {
                let response = <CommonResponse> data;

                if (response.result) {
                    alert("매장 정보를 수정했습니다.");
                } else {
                    alert("매장 정보를 수정할 수 없습니다. \r\n입력하신 정보를 다시 확인해 주세요.");
                }
            });
    }

    private checkValidation() : boolean {
        // if (this.loginUsers.name == "") {
        //     alert("매장명을 입력해 주세요.");
        //     return false;
        // }

        if (this.loginUsers.areas.seq == 0) {
            alert("지역을 선택해 주세요.");
            return false;
        }

        if (this.loginUsers.storeUsers.storeTypes.seq == 0) {
            alert("매장 종류를 선택해 주세요.");
            return false;
        }

        console.log(Number.isInteger(Number(this.loginUsers.storeUsers.elapsedTime)));
        if (!Number.isInteger(Number(this.loginUsers.storeUsers.elapsedTime))) {
            alert("소요시간은 숫자만 입력해 주세요.");
            return false;
        }
        this.loginUsers.storeUsers.elapsedTime = Number(this.loginUsers.storeUsers.elapsedTime);

        return true;
    }

    private fileEvent(event : any) : void {
        this.storeImageFile = event.target.files[0];
        this.loginUsers.storeUsers.storeImage = this.storeImageFile.name;
      }
    
    private fileUpload() : void {
        console.log('file upload');
        console.log(this.storeImageFile);

        if (this.storeImageFile == null) {
            this.setUserData();
        } else {
            let formData : FormData = new FormData();
            formData.append("file", this.storeImageFile);

            let xhr = new XMLHttpRequest();
            var self = this;

            xhr.onreadystatechange = function () {
                console.log(xhr);

                if (xhr.readyState == 4) {
                    let responseText = xhr.responseText;
                    let response : CommonResponse = <CommonResponse> JSON.parse(responseText);
                    console.log(response);

                    if (response.result) {
                    let fileData : FileData = <FileData> response.data;

                    self.loginUsers.storeUsers.storeImage = fileData.fileName;
                    self.loginUsers.storeUsers.storeImageSize = fileData.fileSize;
                    self.setUserData();
                    }
                }
            }

            xhr.open("POST", API_URL.UPLOAD_STORE_IMAGE, true);
            xhr.setRequestHeader("jwt-auth-token", localStorage.getItem("token"));
            xhr.send(formData);
        }
    }

    public setAddressApi(data) {
        console.log(data);
        this.loginUsers.storeUsers.zipCode = data.zip;
        this.loginUsers.address = data.addr;

        var geocoder = new daum.maps.services.Geocoder();
        
        geocoder.addressSearch(data.addr, function(result, status) {
            console.log(result);
            console.log(status);

            if (status === daum.maps.services.Status.OK) {
                var coords = new daum.maps.LatLng(result[0].y, result[0].x);
                this.loginUsers.storeUsers.latitude = Number(result[0].y).toFixed(10);
                this.loginUsers.storeUsers.longitude = Number(result[0].x).toFixed(9);
                console.log(Number(result[0].y).toFixed(10));
                console.log(Number(result[0].x).toFixed(9));
            }
        }.bind(this));

        console.log(this.loginUsers);

        this.chRef.detectChanges();
    }
}