import { Menus } from "src/app/models/menus/Menus";
import { Options } from "src/app/models/menus/Options";
import { Users } from "../users/Users";

export class MenuOptions {
  constructor(
    public seq : number = 0,
    public menus : Menus = new Menus(),
    public name : string = "",
    public engName : string = "",
    public chnName : string = "",
    public jpnName : string = "",
    public isUsed : boolean = true,
    public isRequired : boolean = false,
    public users : Users = new Users(),
    public optionList : Options[] = new Array<Options>(),
    public edit : boolean = false,
    public sub : boolean = false,
    public checked : boolean = false,
    public exist : boolean = false
  ) {}
}
