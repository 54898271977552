import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";

@Component({
    selector: "",
    template: "<html></html>",
    providers: [AJAXService]
})
export class PhoneAuthController {
    constructor(
        private localStorageService: LocalStorageService,
        private activatedRoute: ActivatedRoute) {

        this.init();
    }

    private init() {
        this.activatedRoute.queryParams.subscribe(params => {
            let isComplete = params["isComplete"] == "true";
            if (isComplete) {
                let phoneNumber = params["phoneNumber"];
                opener.window.callCompletePhoneAuth(phoneNumber);
                window.close();
            } else {
                alert("본인 인증을 실패했습니다.");
            }
        });
    }
}
