// Libraries
import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { Location } from "@angular/common";
import { tokenNotExpired } from "angular2-jwt";

// Services
import { AJAXService } from "src/app/utils/AJAXService";

@Injectable()
export class AuthChecker implements CanActivate {
  constructor(
    private router : Router,
    private location : Location,
    private ajaxService : AJAXService
  ) {}

  canActivate() : boolean {
    if (tokenNotExpired()) {
      return true;
    }

    this.router.navigateByUrl("/login");
    return false;
  }
}
