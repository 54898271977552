import { Component, OnInit } from "@angular/core";
import { AJAXService } from "src/app/utils/AJAXService";
import { Router, NavigationEnd } from "@angular/router";
import { LocalStorageService } from "src/app/utils/LocalStorageService";

import { API_URL } from "src/app/constants/API_URL";
import { CommonResponse } from "src/app/models/CommonResponse";
import { StoreUsers } from "src/app/models/users/StoreUsers";
import { Users } from "src/app/models/users/Users";
import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";

@Component({
  selector : "app-root",
  templateUrl : "../views/html/Main.html",
  styleUrls : ["../views/css/Main.css"],
  providers: []
})

export class MainController implements OnInit {
  private isLoginPage : boolean;
  private loginId : string = "";

  private managementUrl : string[];
  private nowUrl : string = "";
  private pageName : string = "";

  private Users : Users = new Users();
  private isOpen : boolean = true;


  constructor(
    private localStorageService : LocalStorageService,
    private ajaxService : AJAXService,
    private router : Router) {

    this.managementUrl = [
      "/dashboard", "/calculate", "/calculateComplete", "/order", "/menu", "/info"
    ];
  }

  ngOnInit() {
    this.setData();
    this.setIsOpen();
  }

  private setData() : void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.nowUrl = event.url;
        this.setPageName();

        // hide the header when login page
        if (this.isManagementPage(event.url)) {
          this.isLoginPage = false;
          this.loginId = this.localStorageService.getData("user");
        } else {
          this.isLoginPage = true;
        }
      }
    });
  }

  private setIsOpen() : void {
    this.ajaxService
      .getDataWithCondition(API_URL.SET_STORE_ISOPEN, this.loginId)
      .then(data => {
        let response = <CommonResponse> data;

        this.Users = <Users> response.data;
        this.isOpen = this.Users.open;
      });
  }

  private isManagementPage(url : string) : boolean {
    let result : string = this.managementUrl.find(item => item == url);

    if (result == undefined) {
      return false;
    }

    return true;
  }

  private setPageName() : void {
    switch (this.nowUrl) {
      case "/dashboard" :
        this.pageName = "HOME";
        break;
      case "/order" :
        this.pageName = "매출관리";
        break;
      case "/menu" :
        this.pageName = "메뉴관리";
        break;
      case "/info" :
        this.pageName = "매장정보관리";
        break;
      case "/calculate" :
        this.pageName = "정산관리";
        break;
      case "/calculateComplete" :
        this.pageName = "정산관리";
        break;
      default :
        this.pageName = "";
        break;
    }
  }

  private changeMenu(url : string) : void {
    this.router.navigateByUrl(url);
  }

  private logout() : void {
    this.router.navigateByUrl("/login");
  }

  private changeOpen(){

    this.Users.open = this.isOpen;

    this.ajaxService
    .getDataWithCondition(API_URL.UPDATE_STORE_ISOPEN, this.Users)
    .then(data => {
      let response = <CommonResponse> data;
    });
  }
}
