import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule, Routes } from "@angular/router";
import { MyDatePickerModule } from "mydatepicker";
import { DatePipe } from "@angular/common"
import { NgDaumAddressModule } from "ng2-daum-address";

import { routes } from "src/app/config/Routes";

import { MainController } from "src/app/controllers/MainController";
import { LoginController } from "src/app/controllers/LoginController";
import { DashboardController } from "src/app/controllers/DashboardController";
import { OrderController } from "src/app/controllers/OrderController";
import { MenuController } from "src/app/controllers/MenuController";
import { InfoController } from "src/app/controllers/InfoController";
import { CalculateController } from './controllers/CalculateController';

import { AJAXService } from "src/app/utils/AJAXService";
import { LocalStorageService } from "src/app/utils/LocalStorageService";
import { AuthChecker } from "src/app/utils/AuthChecker";
import { PhoneAuthController } from './controllers/PhoneAuthController';
import { WindowRef } from './utils/WindowRef';
import { OnlyNumber } from './utils/OnlyNumber';
import { CalculateCompleteController } from './controllers/CalculateCompleteController';

@NgModule({
  declarations: [
    MainController,
    LoginController,
    DashboardController,
    OrderController,
    MenuController,
    InfoController,
    PhoneAuthController,
    CalculateController,
    CalculateCompleteController,
    OnlyNumber
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    MyDatePickerModule,
    NgDaumAddressModule
  ],
  providers: [
    AJAXService,
    AuthChecker,
    LocalStorageService,
    DatePipe,
    WindowRef
  ],
  bootstrap: [MainController]
})
export class AppModule { }
