import { Injectable } from "@angular/core";

import { Users } from "../models/users/Users";

@Injectable()
export class LocalStorageService {
  public getToken() : string {
    return localStorage.getItem("token");
  }

  public getData(key : string) : string {
    return localStorage.getItem(key);
  }

  public setData(key : string, value : string) : void {
    localStorage.setItem(key, value);
  }

  public remove(key : string) : void {
    localStorage.removeItem(key);
  }

  public getLoginUser() : Users {
    let loginUsers : Users = new Users();
    loginUsers.id = localStorage.getItem("user");

    return loginUsers;
  }

  public clear() : void {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }
}
